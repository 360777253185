// const firebaseConfig = {
//     apiKey: "AIzaSyBD6No3zCT6EDUwM9I7LddtXaGbG22F9UU",
//     authDomain: "uponai.firebaseapp.com",
//     projectId: "uponai",
//     storageBucket: "uponai.appspot.com",
//     messagingSenderId: "410721814161",
//     appId: "1:410721814161:web:f5321dc0edebfae6152130",
//     measurementId: "G-Q6F62E067G"
// }
const firebaseConfig = {
    apiKey: "AIzaSyBRfaVraEGdPgs9UafDsHChEU2pQI7Njjg",
    authDomain: "upon-ai-live.firebaseapp.com",
    projectId: "upon-ai-live",
    storageBucket: "upon-ai-live.appspot.com",
    messagingSenderId: "358309760259",
    appId: "1:358309760259:web:4071450bb8ecf3aeb4ad36",
    measurementId: "G-4TLYGVXTEQ"
};
export default firebaseConfig