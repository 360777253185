const colors = {
    blue: "#2c3e50",
    green: "#19bc9c",
    yellow: "#ffd43a",
    red: "#ea6153",

    grey_light: "#ededed",
    white: "#ffffff",
    black: "#111111"
}
/*
    Alternative
    primary: "#1b3b45",
    secondary: "#879995",
    success: '#729070',
    warning: colors.yellow,
    danger: colors.red,
    info: "#92a391",
    backgroundPrimary: "#c8d1cb",
    backgroundSecondary: "#b0bfb7",
    backgroundElement: "#97ab9f",
    backgroundBorder: "#879995",
*/

const std_light_color_palette = {
    primary: colors.blue,
    secondary: colors.green,
    success: colors.green,
    warning: colors.yellow,
    danger: colors.red,
    info: colors.blue,
    backgroundPrimary: colors.grey_light,
    backgroundSecondary: colors.white,
    backgroundElement: colors.grey_light,
    backgroundBorder: colors.grey_light,
    textPrimary: colors.black,
    textInverted: colors.white,
    shadow: "rgba(44, 62, 80, 0)",

    // US
    menuBackColor: colors.blue,
}

const std_dark_color_palette = {
    primary: colors.grey_light,
    secondary: colors.green,
    success: colors.green,
    warning: colors.yellow,
    danger: colors.red,
    info: colors.blue,
    backgroundPrimary: colors.blue,
    backgroundSecondary: colors.blue,
    backgroundElement: colors.grey_light,
    backgroundBorder: colors.grey_light,
    textPrimary: colors.white,
    textInverted: colors.black,
    shadow: "rgba(255, 255, 255, 0)",

    // US
    menuBackColor: colors.blue,
}

const vuestic_config = {
    config: {
        colors: {

            variables: {
                // US
                menuBackColor: colors.primary,
                greyLight: colors.grey_light,
                // for notifications
                error: colors.red,
            },
            presets: {
                light: std_light_color_palette,
                dark: std_dark_color_palette,
            }
        }
    }
}
export default vuestic_config