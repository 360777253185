import axios from '@/plugins/axios'

export default {
    install(app) {
        app.config.globalProperties.$createNotification = function (
            message,
            {
                title = '',
                type = '' /* success, warning, info, error, danger */
            }
        ) {
            message = ['warning', 'danger', 'error', 'info', 'success'].includes(type) ? `notifications.${message}` : message
            this.$vaToast.init({ message: this.$t(message), title: title, color: type, offsetY: 50 })
        }
        app.config.globalProperties.$formatTime = function (timestamp, type = 'time') {
            let timeStamp = new Date(timestamp),
                first = '',
                second = '',
                third = ''
            if (type == 'time') {
                first = '' + timeStamp.getHours()
                second = '' + timeStamp.getMinutes()
                third = '' + timeStamp.getSeconds()
            } else if (type == 'date') {
                timeStamp = new Date(timestamp)
                first = timeStamp.getFullYear()
                second = '' + (timeStamp.getMonth() + 1)
                third = '' + timeStamp.getDate()
            }

            if (second.length < 2) {
                second = '0' + second
            }
            if (third.length < 2) {
                third = '0' + third
            }
            return type == 'time' ? [first, second, third].join(':') : [first, second, third].join('-')
        }
        app.config.globalProperties.$sortObjectByKey = function (o) {
            return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})
        }
        app.config.globalProperties.$reverseObjectByKey = function (o) {
            var final_obj = {}
            const reversedKeys = Object.keys(o).reverse()
            reversedKeys.forEach(key => { final_obj[key] = o[key] })
            return final_obj
        }
        app.config.globalProperties.$createUserInitials = function (user_obj) {
            let capitals = ''
            if (user_obj.first_name.length) {
                capitals += user_obj.first_name.charAt(0)
            }
            if (user_obj.last_name.length) {
                capitals += user_obj.last_name.charAt(0)
            }
            return capitals.toUpperCase()
        }
        app.config.globalProperties.$countWordsAndCharacters = function (text) {
            const words = text.split(' ').filter(word => word.length)
            return {
                words: words.length,
                characters: text.length
            }
        }
        app.config.globalProperties.$objectNotEmpty = function (o) {
            return Object.keys(o).length !== 0
        }
        app.config.globalProperties.$websocket = function (path) {
            // console.log(app.config.globalProperties.$router)
            let protocol = 'ws'
            let host_root = location.hostname
            if (process.env.NODE_ENV === 'production') {
                protocol += 's'
            } else {
                host_root += ':8000'
            }
            const url = `${protocol}://${host_root}/ws/${path}/`
            try {
                const ws = new WebSocket(url)
                window.onbeforeunload = function () {
                    if (ws.readyState === WebSocket.OPEN) {
                        ws.close()
                    }
                }
                return ws
            } catch (e) {
                return null
            }
        }
        // global method for making axios call
        app.config.globalProperties.$makeAxiosCall = async function (
            url,
            method = 'get',
            data = {},
            config = {}
        ) {
            let axios_root = null
            switch (method.toLowerCase()) {
                case 'get':
                    axios_root = axios.get
                    break
                case 'post':
                    axios_root = axios.post
                    break
            }

            try {
                const response = (await axios_root(url, data, config)).data

                // if success has to be handled
                if ("success" in response && !response.success) {
                    if ("message" in response) {
                        this.$createNotification(response.message, { type: 'error' })
                    }
                    return null
                }
                if ("message" in response) {
                    this.$createNotification(response.message, { type: 'info' })
                }
                // if redirection needed
                if ("redir" in response) {
                    app.config.globalProperties.$router.replace({ name: response.redir })
                } else {
                    return response
                }
            } catch (e) {
                this.$createNotification('server_error', { type: 'error' })
                console.log(e)
                return null
            }
        }
    }
}