import { createApp } from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@/plugins/fontawesome'

import router from '@/plugins/router'
import '@/assets/css/hl-styles/atom-one-light.css'

import AppGlobals from '@/plugins/AppGlobals'

import App from './App.vue'

import { createVuestic } from 'vuestic-ui'
import 'vuestic-ui/css'
import vuestic_config from './plugins/vuestic_config'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "@/assets/css/main.css"
import i18n from './plugins/i18n'

import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import firebaseConfig from './plugins/firebase'

// Initialize Firebase and Analytics
getAnalytics(initializeApp(firebaseConfig))

const app = createApp(App, {})
app.use(i18n)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(AppGlobals)
app.use(createVuestic(vuestic_config))
app.mount('#app')
