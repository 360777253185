export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar"])},
  "ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Română"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "no_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Chatverlauf"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "clearservicetempo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In die Zwischenablage kopieren"])},
  "reader_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leseransicht"])},
  "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesezeichen"])},
  "satisfying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufriedenstellend"])},
  "inadequate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unzureichend"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drucken"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "describe_problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beschreiben Sie das Problem"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "no_files_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Dokumente zu diesem Projekt hochgeladen"])},
  "upload_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hochladen"])},
  "drop_files_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hier ablegen"])},
  "select_or_create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen oder erstellen Sie ein Projekt"])},
  "new_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Projekt"])},
  "search_or_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen oder erstellen"])},
  "prompt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen Sie Sinclair (Shift + Enter für mehrere Zeilen)"])},
  "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bsp."])},
  "sure_want_to_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie löschen möchten"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "user_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatliste"])},
  "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesezeichen"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschlossen"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichen"])},
  "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörter"])},
  "file_upload_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "sign_up_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen eines neuen Kontos"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit Google-Konto"])},
  "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit Facebook-Konto"])},
  "login_with_github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit GitHub-Konto"])},
  "and_start_using_uponai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und beginnen Sie mit der Nutzung von Upon:AI"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
  "thread_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe zum Projekt"])},
  "files_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der hochgeladenen Dateien aus dem möglichen Maximum"])},
  "filter_to_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhaltung auf dieses Projekt filtern"])},
  "shows_the_whole_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige des gesamten Chatverlaufs des Assistenten"])},
  "shows_the_threads_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige des Chatverlaufs nur für das Projekt"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lass uns kennenlernen!"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertigstellen"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jährlich"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monatlich"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["täglich"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StartUp"])},
  "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreativ"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
  "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bald"])},
  "choose_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich wähle das"])},
  "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlichen Glückwunsch"])},
  "plan_chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr gewähltes Paket"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
  "i_agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme zu"])},
  "with_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["den Nutzungsbedingungen zu"])},
  "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
  "forward_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleiten an alle"])},
  "forward_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleiten an den Gruppenleiter"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe"])},
  "task_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe abschließen"])},
  "clearserviceforecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstplan"])},
  "assistants": {
    "live_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello Team"])},
    "buddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassenkamerad"])},
    "image_prompter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildprompter"])},
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwickler"])},
    "translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer"])},
    "web_crawler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-Crawler"])},
    "brainiac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genie"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
    "copywriter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texter"])}
  },
  "notifications": {
    "err_fill_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie die erforderlichen Felder aus"])},
    "file_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument gelöscht"])},
    "wrong_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine gültige Eingabeaufforderung ein"])},
    "server_conn_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zum Server fehlgeschlagen"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht autorisiert"])},
    "wrong_file_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei konnte nicht verarbeitet werden"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden"])},
    "error_logging_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemfehler. Versuchen Sie es erneut!"])},
    "invalid_login_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Anmeldedaten"])},
    "user_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer inaktiv"])},
    "already_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits angemeldet"])},
    "server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverfehler"])},
    "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke für Ihr Feedback!"])},
    "invalid_lang_iso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Sprach-ISO-Code"])},
    "language_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache geändert"])},
    "language_loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache geladen"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In die Zwischenablage kopiert"])},
    "thread_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt nicht gefunden"])},
    "thread_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt gelöscht"])},
    "file_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei nicht gefunden"])},
    "file_name_too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname zu lang"])},
    "file_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateigröße zu groß"])},
    "text_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text nicht gefunden"])},
    "max_files_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Anzahl von Dateien erreicht"])},
    "invalid_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Token"])},
    "user_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger oAuth-Benutzer"])},
    "user_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer bereits mit denselben Anmeldeinformationen registriert"])},
    "popup-blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlauben Sie Pop-up-Fenster"])},
    "account-exists-with-different-credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Konto existiert bereits mit einer anderen Anmeldeinformation"])},
    "error_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Speichern"])},
    "bookmark_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Gespräch markiert"])},
    "bookmark_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesezeichen entfernt"])},
    "no_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nicht genug Energie, um die Aktion auszuführen"])},
    "you_must_agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen den Nutzungsbedingungen zustimmen"])}
  },
  "steps-locals": {
    "personal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Daten"])},
    "choose-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakete"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "free_trial_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kostenlose Probezeit"])},
    "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlose Testphase, in der Sie die Vorteile des von Ihnen gewählten Upon:AI-Pakets uneingeschränkt nutzen können. Danach können Sie das Paket abonnieren oder es kostenlos kündigen."])},
    "number_choosable_mi_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenten"])},
    "number_choosable_mi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement-Paket enthält künstliche Intelligenz-Assistenten auf verschiedenen Ebenen und mit unterschiedlichen Fähigkeiten. Wählen Sie das Paket, das Ihren Bedürfnissen am besten entspricht."])},
    "basic_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegende KIs"])},
    "basic_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Assistenten sind schneller, haben aber begrenzte Fähigkeiten. Sie sind ideal für einfachere Aufgaben und die Anzahl der eingebbaren Zeichen ist ebenfalls begrenzt (max. 2000 Zeichen)."])},
    "advanced_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortgeschrittene KIs"])},
    "advanced_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Assistenten sind auf Datenanalyse, Texterstellung und andere komplexe Aufgaben ausgerichtet. Obwohl sie langsamer sein können als grundlegende KIs, sind sie viel intelligenter."])},
    "energy_per_day_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie / Tag"])},
    "energy_per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Aktion, die Sie mit der KI durchführen, erfordert eine bestimmte Menge an Energie. Grundlegende KIs verbrauchen weniger Energie, während fortgeschrittene KIs mehr verbrauchen."])},
    "max_chars_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateneingabe in Zeichen"])},
    "max_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement-Paket bestimmt, wie viel Text Sie auf einmal in den Assistenten eingeben können. Größere Pakete verarbeiten größere Mengen an Text. Die Menge der Texteingabe hängt auch vom jeweiligen Assistenten ab."])},
    "chat_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online-Unterstützung im Chat"])},
    "chat_tel_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online-Unterstützung per Chat und Telefon"])},
    "chat_tel_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Hilfe benötigen, können Sie je nach Paket Live-Unterstützung per Chat oder Telefon erhalten."])},
    "upload_file_analysis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen von Textdateien, Tabellen oder Webseiten zur Datenanalyse"])},
    "upload_file_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige KI-Modelle können gescannte oder textbasierte Dokumente, Tabellen usw. von mehreren hundert Seiten, sowie den Text von Webseiten verarbeiten und diese für die Beantwortung nutzen."])},
    "choosable_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenten"])},
    "choosable_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je nach Abonnement-Paket haben Sie Zugang zu verschiedenen Künstlichen Intelligenz (KI) Assistenten, jeder mit einer eigenen speziellen \"Persönlichkeit\" und Fähigkeiten."])},
    "choosable_mis_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gelb hervorgehobenen Assistenten sind fortgeschrittene KIs mit größerem Wissen. Einige von ihnen - wie zum Beispiel das Genie - haben die Fähigkeit zum Hochladen von Dateien oder zur Analyse von Webseiten."])},
    "choosable_mis_highlight_details_link_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier finden Sie eine detaillierte Beschreibung der verschiedenen Assistenten..."])},
    "professional_newsletters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professionelle Newsletter"])},
    "professional_newsletters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nützliche Informationen, Tipps und Tricks für die effiziente und einfache Nutzung der Upon:AI-Assistenten. Die Newsletter kommen etwa wöchentlich."])},
    "plans_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testen Sie alle Funktionen 14 Tage lang kostenlos und wählen Sie dann das Paket, das am besten zu Ihnen passt."])},
    "company_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte teilen Sie uns die grundlegenden Informationen über Ihr Unternehmen oder Ihre Institution mit, in denen Sie aktiv sind. Diese Daten helfen uns, Ihre Bedürfnisse besser zu verstehen und einen personalisierten Service zu bieten."])},
    "company_or_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Unternehmens oder der Institution"])},
    "example_institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen oder Bildungseinrichtung"])},
    "company_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "example_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer, Marketing, Schüler, Lehrer"])},
    "company_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurze Beschreibung"])},
    "example_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist Ihre Hauptaufgabe oder Aktivität? Nach welchen Prinzipien führen Sie Ihre Arbeit aus? Welche Art von Studien verfolgen Sie?"])}
  }
}