<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route(nv) {
      document.title = this.$t(nv.name || nv.meta.title) + ' | Upon:AI'
    }
  }
}
</script>
