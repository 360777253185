import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faGoogle,
    faGithub,
    faFacebook
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faGithub,
    faGoogle,
    faFacebook
)