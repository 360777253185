export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar"])},
  "ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Română"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acasă"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bun venit"])},
  "no_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun istoric al discuțiilor"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablou de bord"])},
  "clearservicetempo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieșire"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume de utilizator"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă"])},
  "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmp obligatoriu"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiază în clipboard"])},
  "reader_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizare cititor"])},
  "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semn de carte"])},
  "satisfying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfăcător"])},
  "inadequate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inadecvat"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printează"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare"])},
  "describe_problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te rog descrie problema"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proiect"])},
  "no_files_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun document încărcat în acest proiect"])},
  "upload_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încarcă fișiere"])},
  "drop_files_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasă fișierele aici"])},
  "select_or_create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectează sau creează proiect"])},
  "new_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proiect nou"])},
  "search_or_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caută sau creează"])},
  "prompt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Întreabă Sinclair (Shift + Enter pentru multilinie)"])},
  "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex."])},
  "sure_want_to_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ești sigur că vrei să ștergi"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulare"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șterge"])},
  "user_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listă discuții"])},
  "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semne de carte"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["închis"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractere"])},
  "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuvinte"])},
  "file_upload_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])},
  "sign_up_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crearea unui cont nou"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mă înregistrez"])},
  "login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu Google"])},
  "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu Facebook"])},
  "login_with_github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificare cu GitHub"])},
  "and_start_using_uponai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["și începeți să utilizați Upon:AI"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sau"])},
  "thread_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajutor pentru proiect"])},
  "files_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul de fișiere încărcate din maximul posibil"])},
  "filter_to_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrează conversația pentru acest proiect"])},
  "shows_the_whole_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișează întreaga istorie a chat-ului asistentului"])},
  "shows_the_threads_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișează istoria chat-ului doar pentru proiect"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["implicit"])},
  "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai să ne cunoaștem!"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Următorul"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizare"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenume"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume de familie"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trec peste"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lună"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunar"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zi"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zilnic"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StartUp"])},
  "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creativ"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
  "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În curând"])},
  "choose_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aleg acesta"])},
  "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felicitări"])},
  "plan_chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pachetul ales"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiră"])},
  "i_agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunt de acord"])},
  "with_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cu termenii și condițiile"])},
  "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termeni și condiții"])},
  "forward_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecționează tuturor"])},
  "forward_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecționează liderului de grup"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarcină"])},
  "task_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închiderea sarcinii"])},
  "clearserviceforecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programare"])},
  "assistants": {
    "live_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello Team"])},
    "buddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleg de clasă"])},
    "image_prompter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prompter de imagini"])},
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezvoltator"])},
    "translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traducător"])},
    "web_crawler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizator de site-uri"])},
    "brainiac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geniu"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor"])},
    "copywriter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copywriter"])}
  },
  "notifications": {
    "err_fill_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te rog, completează corect toate câmpurile!"])},
    "file_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document șters"])},
    "wrong_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te rog, introdu o comandă corectă"])},
    "server_conn_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexiunea la server a eșuat"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neautorizat"])},
    "wrong_file_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu am putut procesa fișierul"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu a fost găsit"])},
    "error_logging_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare de sistem. Încearcă din nou!"])},
    "invalid_login_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de autentificare invalide"])},
    "user_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator inactiv"])},
    "already_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ești deja autentificat"])},
    "server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare de server"])},
    "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulțumim pentru feedback!"])},
    "invalid_lang_iso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cod ISO de limbă incorect"])},
    "language_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limba a fost schimbată"])},
    "language_loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limba a fost încărcată"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiat în clipboard"])},
    "thread_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proiectul nu a fost găsit"])},
    "thread_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proiect șters"])},
    "file_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișierul nu a fost găsit"])},
    "file_name_too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele fișierului este prea lung"])},
    "file_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiunea fișierului este prea mare"])},
    "text_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textul nu a fost găsit"])},
    "max_files_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai atins numărul maxim de fișiere"])},
    "invalid_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token invalid"])},
    "user_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator oAuth invalid"])},
    "user_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorul este deja înregistrat cu aceleași date de autentificare"])},
    "popup-blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permite ferestrele pop-up"])},
    "account-exists-with-different-credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contul există deja cu o altă metodă de autentificare"])},
    "error_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare la salvare"])},
    "bookmark_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversația a fost adăugată la favorite"])},
    "bookmark_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcajul a fost înlăturat"])},
    "no_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu ai suficientă energie pentru a executa acestă acțiune"])},
    "you_must_agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebuie să accepți termenii și condițiile de utilizare"])}
  },
  "steps-locals": {
    "personal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații personale"])},
    "choose-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pachete"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizație"])},
    "free_trial_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perioadă de încercare gratuită"])},
    "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioadă de încercare gratuită în care poți beneficia de toate avantajele pachetului Upon:AI ales de tine, fără restricții. Ulterior, poți alege să te abonezi la pachetul respectiv sau să renunți gratuit."])},
    "number_choosable_mi_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asistent"])},
    "number_choosable_mi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pachetul tău de abonament include asistenți de inteligență artificială de diferite niveluri și abilități. Alege pachetul care îți satisface cel mai bine nevoile."])},
    "basic_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistenți AI de bază"])},
    "basic_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acești asistenți sunt mai rapizi, dar cu abilități limitate. Sunt ideali pentru sarcini simple și au un număr limitat de caractere pe care le poți introduce (max. 2000 caractere)."])},
    "advanced_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistenți AI avansați"])},
    "advanced_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acești asistenți sunt optimizați pentru analiza datelor, scrierea de texte și alte sarcini complexe. Deși pot fi mai lenti decât asistenții AI de bază, sunt mult mai inteligenți."])},
    "energy_per_day_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["energie / zi"])},
    "energy_per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiecare operațiune pe care o efectuezi cu AI consumă o anumită cantitate de energie. Asistenții AI de bază consumă mai puțină energie, în timp ce cei avansați consumă mai mult."])},
    "max_chars_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intrare de date cu caractere"])},
    "max_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pachetul tău de abonament determină cât de mult text poți introduce odată asistentului. Pachetele mai mari permit introducerea unei cantități mai mari de text. Cantitatea de text introdusă depinde și de asistentul specific."])},
    "chat_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistență online prin chat"])},
    "chat_tel_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistență online prin chat și telefon"])},
    "chat_tel_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă ai nevoie de ajutor, poți primi asistență live prin chat sau telefon, în funcție de pachetul tău."])},
    "upload_file_analysis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați un fișier text, un tabel sau o pagină web pentru analiză"])},
    "upload_file_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anumite modele AI pot procesa documente scanate sau de text, tabele etc. cu sute de pagini, precum și textul de pe pagini web, folosindu-le pentru a răspunde."])},
    "choosable_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistenți"])},
    "choosable_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În funcție de pachetul tău de abonament, ai acces la diferiți asistenți de inteligență artificială (AI), fiecare având propria sa \"personalitate\" și abilități."])},
    "choosable_mis_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistenții evidențiați în galben sunt AI-uri mai sofisticate și avansate. Unele dintre acestea - cum ar fi Trust Brain - au abilități de încărcare a fișierelor sau de analiză a paginilor web."])},
    "choosable_mis_highlight_details_link_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Găsiți o descriere detaliată a diferiților asistenți aici..."])},
    "professional_newsletters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buletine de știri profesionale"])},
    "professional_newsletters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informații utile, sfaturi, trucuri pentru o utilizare mai eficientă și mai simplă a asistenților Upon:AI. Buletinele de știri vin aproximativ săptămânal."])},
    "plans_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încearcă toate caracteristicile gratuit timp de 14 zile, apoi alege pachetul care ți se potrivește cel mai bine."])},
    "company_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să ne oferiți informațiile de bază despre afacerea sau instituția dvs. unde sunteți activ. Aceste informații ne ajută să înțelegem mai bine nevoile dvs. și să oferim un serviciu mai personalizat."])},
    "company_or_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele afacerii sau instituției"])},
    "example_institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afacere sau instituție de învățământ"])},
    "company_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcție"])},
    "example_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director, marketing, student, profesor"])},
    "company_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriere scurtă"])},
    "example_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Care este principala ta sarcină sau activitate? Cum îți faci treaba în conformitate cu principiile? Ce fel de studii urmezi?"])}
  }
}