export default {
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar"])},
  "ro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Română"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Főoldal"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözöllek"])},
  "no_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincsenek cset előzmények"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Műszerfal"])},
  "clearservicetempo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csevegés"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
  "log_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépek"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználónév"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
  "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kötelező mező"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másold a vágólapra"])},
  "reader_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvasó nézet"])},
  "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Könyvjelzőzd"])},
  "satisfying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendben van"])},
  "inadequate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem megfelelő"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomtatás"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keress rá"])},
  "describe_problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek, írd le a problémát"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["projekt"])},
  "no_files_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs dokumentum feltöltve ehhez a projekthez"])},
  "upload_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájlok feltöltése"])},
  "drop_files_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobd ide a fájlokat"])},
  "select_or_create_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz ki vagy hozz létre projektet"])},
  "new_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új projekt"])},
  "search_or_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kresés vagy létrehozás"])},
  "prompt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdezd Sinclairt (Shift + Enter többsorosan)"])},
  "ex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pl"])},
  "sure_want_to_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan törölni szeretnéd"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
  "user_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csevegések"])},
  "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Könyvjelzők"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zárt"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["karakter"])},
  "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szó"])},
  "file_upload_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
  "sign_up_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új fiók léterehozása"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regisztrálok"])},
  "login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépek Google fiókkal"])},
  "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépek Facebook fiókkal"])},
  "login_with_github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépek GitHub fiókkal"])},
  "and_start_using_uponai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["és az Upon:AI használatának megkezdése"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vagy"])},
  "thread_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segítség a projekthez"])},
  "files_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltöltött fájlok száma a lehetséges maximumból"])},
  "filter_to_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beszélgetés szűrése erre a projektre"])},
  "shows_the_whole_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az asszisztenshez tartozó teljes csevegéstörténet megjelenítése"])},
  "shows_the_threads_chat_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak a projekthez tartozó csevegéstörténet megjelenítése"])},
  "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alapértelmezett"])},
  "steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismerkedjünk!"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előző"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befejezés"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresztnév"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átugrom"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["év"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["éves"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hónap"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["havi"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nap"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napi"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diák"])},
  "startup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StartUp"])},
  "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreatív"])},
  "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalati"])},
  "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hamarosan"])},
  "choose_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt választom"])},
  "congrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulálunk"])},
  "plan_chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kiválasztott csomagod"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eddig érvényes"])},
  "i_agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elfogadom"])},
  "with_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a felhasználói feltételeket"])},
  "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználói feltételek"])},
  "forward_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Továbbítás mindenkinek"])},
  "forward_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Továbbítás a csoportvezetőnek"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladat"])},
  "task_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladat lezárása"])},
  "clearserviceforecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beosztás"])},
  "assistants": {
    "live_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello Team"])},
    "buddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osztálytárs"])},
    "image_prompter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kép prompter"])},
    "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejlesztő"])},
    "translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fordító"])},
    "web_crawler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weboldal elemző"])},
    "brainiac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agytröszt"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanár"])},
    "copywriter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketinges"])}
  },
  "notifications": {
    "err_fill_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek, tölts ki helyesen minden mezőt!"])},
    "file_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentum törölve"])},
    "wrong_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek, írj be egy helyes utasítást"])},
    "server_conn_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült kapcsolódni a szerverhez"])},
    "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs engedélyezve"])},
    "wrong_file_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem tudtam feldolgozni a fájlt"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem találom"])},
    "error_logging_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendszerhiba. Próbáld újra!"])},
    "invalid_login_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen belépési adatok"])},
    "user_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktív felhasználó"])},
    "already_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Már be vagy jelentkezve"])},
    "server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerverhiba"])},
    "thanks_for_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönjük a visszajelzést!"])},
    "invalid_lang_iso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helytelen nyelvi ISO kód"])},
    "language_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv megváltoztatva"])},
    "language_loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv betöltve"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolva a vágólapra"])},
    "thread_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található a projekt"])},
    "thread_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt törölve"])},
    "file_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található a fájl"])},
    "file_name_too_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fájlnév túl hosszú"])},
    "file_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túl nagy a fájlméret"])},
    "text_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem található szöveg"])},
    "max_files_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elérted a fájlok maximális számát"])},
    "invalid_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen token"])},
    "user_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvénytelen oAuth felhasználó"])},
    "user_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználó már regisztrált ugyanazzal a hitelesítő adattal"])},
    "popup-blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyezd a felugró ablakokat (pop-up)"])},
    "account-exists-with-different-credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fiók már létezik egy másik bejelentkezési móddal"])},
    "error_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba a mentéskor"])},
    "bookmark_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Könyvjelzőzted a beszélgetést"])},
    "bookmark_removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Könyvjelző eltávolítva"])},
    "no_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs elég energiád a művelet végrehajtásához"])},
    "you_must_agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kötelező elfogadd a felhasználói feltételeket"])}
  },
  "steps-locals": {
    "personal-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adataim"])},
    "choose-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csomagok"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szervezet"])},
    "free_trial_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingyenes próbaidőszak"])},
    "free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingyenes próbaidőszak, amely alatt korlátozás nélkül élvezheted az általad kiválasztott Upon:AI csomag előnyeit. Ezután előfizethetsz az adott csomagra vagy ingyenesen lemondhatod azt."])},
    "number_choosable_mi_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asszisztens"])},
    "number_choosable_mi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előfizetési csomagod különböző szintű és képességű mesterséges intelligencia asszisztenseket tartalmaz. Válaszd azt a csomagot, amelyik a legjobban kiszolgálja az igényeidet."])},
    "basic_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapszintű MI-k"])},
    "basic_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezek az asszisztensek gyorsabbak, de korlátozottabbak a képességeikben. Ideálisak egyszerűbb feladatokra, és a beírható karakterek száma is korlátozott (max. 2000 karakter)."])},
    "advanced_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejlett MI-k"])},
    "advanced_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezek az asszisztensek adatelemzésre, szövegírásra és egyéb komplex feladatokra vannak hangolva. Bár lassabbak lehetnek, mint az alapszintű MI-k, sokkal intelligensebbek azoknál."])},
    "energy_per_day_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["energia / nap"])},
    "energy_per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden művelet, amit az MI-vel végeztetsz, bizonyos mennyiségű energiát igényel. Az alapszintű MI-k kevesebb energiát használnak, míg a fejlett MI-k többet."])},
    "max_chars_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["karakteres adatbevitel"])},
    "max_chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előfizetési csomagod meghatározza, hogy mennyi szöveget írhatsz be egyszerre az asszisztensnek. A nagyobb csomagok nagyobb szövegmennyiséget kezelnek. A szövegbevitel mennyisége függ az adott asszisztenstől is."])},
    "chat_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online támogatás csetben"])},
    "chat_tel_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online támogatás csetben és telefonon"])},
    "chat_tel_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha segítségre van szükséged, élő támogatást kaphatsz cseten vagy telefonon keresztül, csomagtól függően."])},
    "upload_file_analysis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szöveges fájl, táblázat vagy weboldal feltöltése adatfeldolgozásra"])},
    "upload_file_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizonyos MI modellek képesek feldolgozni akár több száz oldalas szkennelt vagy szöveges dokumentumokat, táblázatokat stb., illetve weboldalak szövegét, így azokat felhasználják a válaszadásban."])},
    "choosable_mis_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asszisztensek"])},
    "choosable_mis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előfizetési csomagtól függően hozzáférsz különböző mesterséges intelligencia (MI) asszisztensekhez, mindegyiknek saját speciális \"személyisége\" van, illetve képességei."])},
    "choosable_mis_highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sárgával kiemelt asszisztensek nagyobb tudású, fejlettebb MI-k. Ezek némelyike - mint például az Agytröszt - rendelkezik fájlfeltöltési vagy weboldal elemzői képességekkel."])},
    "choosable_mis_highlight_details_link_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A különböző asszisztensekről itt találsz részletes leírást..."])},
    "professional_newsletters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professzionális hírlevelek"])},
    "professional_newsletters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasznos információk, tippek, trükkök az Upon:AI asszisztensek hatékonyabb és egyszerűbb használatához. A hírlevelek nagyjából hetente érkeznek."])},
    "plans_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbálj ki minden funkciót ingyen 14 napig, majd válaszd ki a számodra legmegfelelőbb csomagot."])},
    "company_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérlek, oszd meg velünk a vállalkozásod vagy intézményed alapvető információit, ahol aktívan tevékenykedsz. Ezek az adatok segítenek nekünk abban, hogy jobban megértsük az igényeidet és személyre szabottabb szolgáltatást tudjunk nyújtani."])},
    "company_or_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalkozás vagy intézmény neve"])},
    "example_institute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vállalkozás vagy tanintézmény"])},
    "company_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beosztás"])},
    "example_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyvezető, marketing, tanuló, tanár"])},
    "company_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rövid leírás"])},
    "example_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi a fő feladatod vagy tevékenységed? Milyen elvek szerint végzed a munkád? Milyen profilú tanulmányokat folytatsz?"])}
  },
  "clearservice": {
    
  }
}