import { createRouter, createWebHistory } from 'vue-router'
import axios from '@/plugins/axios'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    redirect: { name: 'login' },
    meta: {
      noAccessForAuth: true,
    }
  },
  {
    path: '/sign-up/',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUpView.vue'),
    meta: {
      noAccessForAuth: true,
    }
  },
  {
    path: '/login/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      noAccessForAuth: true,
    }
  },
  {
    path: '/logout/',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/LogoutView.vue'),
  },
  {
    path: '/my-dashboard/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */'../views/DashboardView.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/lets-get-to-know-each-other/',
        name: 'steps',
        components: {
          dashboard_content: () => import(/* webpackChunkName: "wizard" */ '../views/WizardView.vue')
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'clearservice-tempo/',
        name: 'clearservicetempo',
        components: {
          dashboard_content: () => import(/* webpackChunkName: "clearservicetempo" */'@/components/clearservice/ClearserviceTempo.vue')
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'clearservice-forecast/',
        name: 'clearserviceforecast',
        components: {
          dashboard_content: () => import(/* webpackChunkName: "clearserviceforecast" */'@/components/clearservice/ClearserviceForecast.vue')
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'chat/',
        name: 'chat',
        components: {
          dashboard_content: () => import(/* webpackChunkName: "chat" */'@/views/ChatView.vue')
        },
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: "/:catchAll(.*)",
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: {
      title: 'home',
      noAccessForAuth: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // verifying the user is authenticated
  let isAuthenticated = false
  try {
    const response = (await axios.get('/user/auth-state-basic/')).data
    isAuthenticated = response.success
  } catch (error) {
    return next({ name: 'login' })
  }

  const route_requires_auth = to.matched.some((record) => record.meta.requiresAuth)

  // first redirect to steps if needed, from whereever the logged in user is
  // need to watch if endpoint needs authentication for logout to work
  if (route_requires_auth && isAuthenticated) {
    const steps = (await axios.get('/user/signup-step/')).data
    // console.log(steps)
    if (steps && steps.length > 0 && to.name != 'steps') {
      // if incomplete step found, redirect to steps
      for (var i in steps) {
        if (steps[i].completion_type == "IC") {
          return next({ name: 'steps' })
        }
      }
      // redirect to dashboard if all steps complated and still on steps page
      // this resolves the reload at the end of steps to redirect on the first enabled module
    } else if (steps && steps.length == 0 && to.name == 'steps') {
      return next({ 'name': 'dashboard' })
    }
  }
  // restrict access to this route for unauthenticated users
  if (route_requires_auth && !isAuthenticated) {
    return next({ name: 'login' })
  }
  // restrict access to this route for authenticated users
  if (to.matched.some((record) => record.meta.noAccessForAuth) && isAuthenticated) {
    return next({ name: 'dashboard' })
  }
  // Continue resolving the route
  next()
})

export default router
